<template>
  <!--
    A component made to change the Preview SVG while inputting data.
  -->
  <td class="previewInput">
    <div class="input-group">
      <div class="input-group-prepend hide-down-xl">
        <button
          v-if="buttonSwitch"
          class="btn btn-secondary btn-sm btn-block btn-clock-sm btn-sm-block"
          @click="changeValue(elementValuePixel, altElementKey, -1)"
        >
          <font-awesome-icon
            icon="minus"
          />
        </button>
        <button
          v-else
          class="btn btn-secondary btn-sm btn-block btn-clock-sm btn-sm-block"
          disabled
        >
          <font-awesome-icon
            icon="minus"
          />
        </button>
      </div>
      <input
        :value="elementValuePixel"
        :max="maxElementValuePixel"
        :class="['form-control form-control-sm', { 'is-invalid': $parent.hasFieldName(elementKey) }]"
        type="number"
        min="0"
        step="0.000001"
        :onchange="`validity.valid || (Number(value) < 0 ? value = 0 : value = ${maxElementValuePixel});`"
        @change="changeValue($event.target.value, altElementKey)"
      >
      <div class="input-group-append hide-down-xl">
        <button
          v-if="elementValuePixel < maxElementValuePixel"
          class="btn btn-secondary btn-sm btn-block btn-clock-sm btn-sm-block"
          @click="changeValue(elementValuePixel, altElementKey, 1)"
        >
          <font-awesome-icon
            class="mr-2"
            icon="plus"
          />
        </button>
        <button
          v-else
          class="btn btn-secondary btn-sm btn-block btn-clock-sm btn-sm-block"
          disabled
        >
          <font-awesome-icon
            class="mr-2"
            icon="plus"
          />
        </button>
      </div>
    </div>
    <span
      v-show="$parent.hasFieldName(elementKey)"
      class="badge badge-danger"
    >{{ $parent.firstMessage(elementKey) }}</span>
  </td>
</template>

<script>
export default {
  name: 'PreviewInput',
  props: {
    elementKey: {
      type: String,
      required: true
    },
    altElementKey: {
      type: String,
      required: true
    },
    buttonSwitch: {
      type: Boolean,
      required: true
    },
    elementValue: {
      type: Number,
      required: true
    },
    maxElementValue: {
      type: Number,
      required: true
    }
  },
  computed: {
    elementValuePixel () {
      if (this.elementKey == 'Height' || this.elementKey == 'TopOffset' ) {
        return Math.round(this.elementValue * 1080 / 100);
      }
      if (this.elementKey == 'Width' || this.elementKey == 'LeftOffset' ) {
        return Math.round(this.elementValue * 1920 / 100);
      }
      return '';
    },
    maxElementValuePixel () {
      if (this.elementKey == 'Height' || this.elementKey == 'TopOffset' ) {
        return Math.round(this.maxElementValue * 1080 / 100);
      }
      if (this.elementKey == 'Width' || this.elementKey == 'LeftOffset' ) {
        return Math.round(this.maxElementValue * 1920 / 100);
      }
      return '';
    }
  },
  mounted () {
    //console.log(this.elementValue);
  },
  methods: {
    changeValue (elementValuePixel, altElementKey, step) {
      let transformedValue = 0;
      let transformedStep = 0;
      if (this.elementKey == 'Height' || this.elementKey == 'TopOffset' ) {
        transformedValue = elementValuePixel / 1080 * 100;
        if (step)
        {
          transformedStep = step / 1000 * 100;
        }
      }
      if (this.elementKey == 'Width' || this.elementKey == 'LeftOffset' ) {
        transformedValue = elementValuePixel / 1920 * 100;
        if (step)
        {
          transformedStep = step / 1920 * 100;
        }
      }
      this.$emit('changeAttribute', Number(transformedValue) + transformedStep, altElementKey)
    }
  }
  
}
</script>

<style scoped>
td {
  overflow: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
</style>
